import React from 'react'
import './landing.css';
import Insta from '../assets/Instagram-Logo.png';


export default function Food() {
    return (
        <div className='Instagram'>
            <p className='Instagram'>

            <a className='link' href='https://forms.gle/hnDe86vecACfi3ZJA'>Click here to apply for Dakimakura Club!</a> 
            </p>

        </div>
    )
}

/*
  Our <a className='link' href='https://www.instagram.com/dakimakurainc/'>Instagram page <img src={Insta} alt='instaLogo' className='instaLogo'/></a> 
*/