import React from 'react'
import './rules.css';

export default function triviaRules() {
    return (
        <div className='ruleList'>

            <p className='triviaTop'>Anime trivia team registration:</p>
            <ol>
                <li>
                    Team should be registered by one person - the team captain. Further contact with Dakimakura Inc. should also be done by the captain unless they decided to not participate
                </li>
                <li>
                    Team must consist of 3 to 5 players.
                </li>
                <li>
                    All players must be members of Dakimakura club.
                </li>
                <li>
                    Registered participants will get priority discounted ticket<b>*</b>. If your team composition changes, contact us via <a href='https://www.instagram.com/dakimakurainc/'>instagram</a> or email dakimakurainc@gmail.com to have the priority ticket transferred to someone else.
                </li><li>
                    Limited spots - only 4 teams will be accepted in online registration.
                </li><li>
                    One more team will be drafted from people who do not have teams during the event itself.
                </li><li>
                    Teams will be able to accept extra members if they have less than 5 during the event, before trivia starts.
                </li>
                <li>
                    Rules will be explained before the trivia and if needed during the trivia. Don't be shy to ask if something isn't clear!
                </li>
                
            </ol>
            <p><b>*</b>Priority tickets will only be provided to the initial registered team members. After the ticket sales are opened to the public, priority is no longer applicable</p>
        </div>

    )
}